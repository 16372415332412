import React from "react";
import Slide from "react-reveal/Slide";

import "bootstrap/dist/css/bootstrap.min.css";
import "./Services.css";

function Services() {
    return (
        <div id="Services" className="container overflow-hidden">
            <h2 className="text-center display-5 p-5 m-0">Наши услуги</h2>

            <div className="row text-white ">
                <div className="col-md-7 m-0 p-1">
                    <Slide left>
                        <div className="rounded bg-dark h-servicesbox">
                            <div className="p-3">
                                <h3 className="w-100 pt-5">Автомобили из Японии, Кореи и Китая под ключ</h3>
                                <p className="">Покупка автомобиля на японском или корейском аукционе с последующим оформлением таможенных документов и отправкой в регионы.</p>
                                <p className="pt-1 w-100 text-muted text-warning text-end ">
                                    <span className="text-warning">от 35.000 р</span>
                                </p>
                            </div>
                        </div>
                    </Slide>
                </div>

                <div className="col-md-5 m-0 p-1">
                    <Slide right>
                        <div className="rounded bg-dark h-servicesbox">
                            <div className="p-3">
                                <h3 className="w-100 pt-5">Растаможка автомобилей из Японии, Кореи и Китая</h3>
                                <p>
                                    Оформление таможенных документов на автомобиль. Помощь в оформлении СБКТС, получении устройства ГЛОНАСС и
                                    электронного ПТС.
                                </p>
                            </div>
                        </div>
                    </Slide>
                </div>

                <div className="col-md-5 m-0 p-1">
                    <Slide left>
                        <div className="rounded bg-dark h-servicesbox">
                            <div className="p-3">
                                <h3 className="w-100 pt-5">Перевозка автомобилей из Владивостока</h3>
                                <p>
                                    После прибытия во Владивосток мы подготовим и отправим ваш
                                    автомобиль в любую точку страны.
                                </p>
                            </div>
                        </div>
                    </Slide>
                </div>

                <div className="col-md-7 m-0 p-1">
                    <Slide right>
                        <div className="rounded bg-dark h-servicesbox">
                            <div className="p-3">
                                <h3 className="w-100 pt-5">Автоподбор во Владивостоке и Омске</h3>
                                <p>
                                    Подберём, проверим и купим для вас автомобиль во Владивостоке и Приморском крае. При необходимости отправим в регионы.
                                </p>
                            </div>
                        </div>
                    </Slide>
                </div>
            </div>
        </div>
    );
}

export default Services;
