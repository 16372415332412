import React, {useState, useEffect} from "react";
import CounterUp from "./CounterUp";
import carsrc from "../Media/carpad.png";
import shipsrc from "../Media/shippad.png";
import mensrc from "../Media/menpad.png";
import timesrc from "../Media/timepad.png";
import "./Achievements.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";

const API = require("../API/API_config");

function Achievements() {
  const [cars_api, setCars_api] = useState([]);
  const getCarsQuantity = async () => {
    const carsQuantity = await axios.get(API.get_cars_quantity);
    var quantity = 504;
    try {
      quantity += carsQuantity.data.result[0].quantity;
    } catch (err) {
      quantity = 504;
    }
    setCars_api(quantity);
  };
  useEffect(() => {
    getCarsQuantity();
  }, []);

  return (
    <div className="container ">
      <h2 className="text-center p-5 m-0">Наша статистика</h2>
      <div className="row p-3">
        <div className="col-md-3 pb-5">
          <div className="col text-center">
            <div className="row yPad rounded">
              <div className="col-5 col-sm-12 order-sm-1">
                <img className="Pad" src={mensrc} alt="фон" />
              </div>
              <div className="col-7 col-sm-12 abs-center order-sm-2">
                <div className="pt-3">
                  <h2>97%</h2>
                  <div>
                    <h5>Довольных клиентов</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 pb-5">
          <div className="col text-center">
            <div className="row yPad rounded">
              <div className="col-7 col-sm-12 abs-center order-sm-2">
                <div className="pt-3">
                  <h2>{cars_api}</h2>
                  <div>
                    <h5>Привезено машин</h5>
                  </div>
                </div>
              </div>
              <div className="col-5 col-sm-12  order-sm-1">
                <img className="Pad" src={carsrc} alt="фон" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 pb-5">
          <div className="col text-center">
            <div className="row yPad rounded">
              <div className="col-5 col-sm-12 order-sm-1">
                <img className="Pad" src={shipsrc} alt="фон" />
              </div>
              <div className="col-7 col-sm-12 abs-center order-sm-2">
                <div className="pt-3">
                  <h2>140 городов</h2>
                  <div>
                    <h5>Отправка по всей России</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3 pb-5 hone">
          <div className="col text-center">
            <div className="row yPad rounded">
              <div className="col-7 col-sm-12 abs-center order-sm-2">
                <div className="pt-3">
                  <h2>15 дней</h2>
                  <div>
                    <h5>Среднее время доставки</h5>
                  </div>
                </div>
              </div>
              <div className="col-5 col-sm-12  order-sm-1">
                <img className="Pad" src={timesrc} alt="фон" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Achievements;

<div className="row">
  <div className="col-5 col-sm-12 order-sm-1"></div>
  <div className="col-7 col-sm-12 abs-center order-sm-2"></div>
</div>;