import React, { useState } from 'react';
import { Container, Nav, Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./MyNavbar.css";
import telegram from "../Media/telegram.png";
import whatsapp from "../Media/whatsapp.png";
import phone from "../Media/phone.png";
import {PHONE} from "../Confinfo";
let url_whatsapp = "whatsapp://send?phone=79143252520";
let url_telegram = "https://telegram.me/JapanAuto38";
let url_phone = `tel: ${PHONE}`;


function MyNavbar(props) {
  const [expanded, setExpanded] = useState(false);
  const scrollToRef = (ref) => {
    setExpanded(false);
    window.scrollTo(0, ref.current.offsetTop - 50, "smooth");
  }

  const our_services = () => scrollToRef(props.Ref_our_services);
  const about_us = () => scrollToRef(props.Ref_about_us);
  const reviews = () => scrollToRef(props.Ref_reviews);
  const questions = () => scrollToRef(props.Ref_questions);
  const ask_request = () => scrollToRef(props.Ref_request);
  const contacts = () => scrollToRef(props.Ref_contacts);
  const cars = () => scrollToRef(props.Ref_cars);

  return (
    <header id="Navbar">
      <Navbar
          expanded={expanded}
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          className="fixed-top"
      >
        <Container>
          <Navbar.Brand
            href="#home"
            className="text-warning logo-link"
          >
            <div className="logo-img">

            </div>
          </Navbar.Brand>

          <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link onClick={about_us}>О нас</Nav.Link>
              <Nav.Link onClick={our_services}>Услуги</Nav.Link>
              <Nav.Link onClick={cars}>Привезённые автомобили</Nav.Link>
              <Nav.Link onClick={questions}>Частые вопросы</Nav.Link>
              <Nav.Link className="buy-car" onClick={ask_request}>Подобрать машину</Nav.Link>
              <Nav.Link onClick={contacts}>Контакты</Nav.Link>
              {/*<a className="nav-link" href="#Ref_about_us">О нас</a>*/}
              {/*<a className="nav-link" href="#Ref_our_services">Услуги</a>*/}
              {/*<a className="nav-link" href="#Ref_cars">Привезённые автомобили</a>*/}
              {/*<a className="nav-link" href="#Ref_questions">Частые вопросы</a>*/}
              {/*<a className="nav-link buy-car" href="#Ref_request">Подобрать машину</a>*/}
              {/*<a className="nav-link" href="#Ref_contacts">Контакты</a>*/}
            </Nav>
          </Navbar.Collapse>
          <div className="header-feedback">
            <a href={url_phone} className="aimg">
              <img
                  className="w-100"
                  src={phone}
                  alt="Логотип телеграм"
              />
            </a>
            <a href={url_telegram} className="aimg">
              <img
                  className="w-100"
                  src={telegram}
                  alt="Логотип телеграм"
              />
            </a>
            <a href={url_whatsapp} className="aimg">
              <img className="w-100" src={whatsapp} alt="картинка телеграм" />
            </a>
          </div>
        </Container>
      </Navbar>
    </header>
  );
}

export default MyNavbar;
